import './Login.css';
import LoginForm from '../../components/login-form';

function App() {
  return (
    <div id="login-page" className="login-page" class="flex h-screen">
        <div id='login-container' class="m-auto rounded">
            <LoginForm/>
        </div>
    </div>
  );
}

export default App;
