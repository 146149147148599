import "./CreateAutomation.css";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from '../../components/navbar';
import CreateAutomationForm from "../../components/createAutomationForm";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
              // ...
            console.log("uid", uid)
        } else {
            console.log("user is logged out")
            navigate('/login')
        }
    })
  }, []);

  return (
    <>
    <div className="min-h-full">
        <Navbar/>
        <main className="home-page mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <CreateAutomationForm/>
        </main>
    </div>
    </>
  );
}

export default App;
