import "./Home.css";
import { auth } from "../../utils/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from '../../components/navbar';
import AutomationList from "../../components/automationList";
import { getUserStatus } from "../../apis/auth";
import { useAuthState } from "react-firebase-hooks/auth";

function App() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [stats, setStats] = useState({
    automationUsage: '0/0',
    parsingUsage: '0/0'
  })
  useEffect(() => {
    if (user) {
        showUserStatus();
    } 
    if(error) {
        console.log("user is logged out")
        navigate('/login')
    }
  }, [user, error, navigate]);

  const showUserStatus = async () => {
    const usrStatus = await getUserStatus()
    setStats({
      automationUsage: `${usrStatus.automationCount}/${usrStatus.maxAutomations}`,
      parsingUsage: `${usrStatus.extractionCount}/${usrStatus.maxExtractions}`
    })
  }

  return (
    <>
    <div className="min-h-full">
        <Navbar/>
        <main className="home-page mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <div class="grid grid-cols-2 gap-4">
              <div className="bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white text-center">
                  Automation usage
                  <br/>
                  <br/>
                  {stats.automationUsage}
                </h5>
              </div>
              <div className="bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white text-center">
                  Parsing monthly usage 
                  <br/>
                  <br/>
                  {stats.parsingUsage}
                </h5>
              </div>
            </div>
            <br/>
            <AutomationList/>
        </main>
    </div>
    </>
  );
}

export default App;
