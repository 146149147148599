import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBI-QYvfe9NBI4PtFMDLBDUARs_BE_ITcU",
  authDomain: "kupiks-ai.firebaseapp.com",
  projectId: "kupiks-ai",
  storageBucket: "kupiks-ai.appspot.com",
  messagingSenderId: "213755729336",
  appId: "1:213755729336:web:2a7234d0a96d7ccf752b4d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)


export default app;