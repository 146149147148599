import "./Login.css";
import { useState } from "react";
import { auth } from "../../utils/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function App() {
    const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const onReset = async () => {
    if (email === '') {
      return
    }
    try {
        await sendPasswordResetEmail(auth, email)
    } catch (error) {
        console.log(error)
    }
    navigate('/login')
  };
  return (
    <div id="login-page" className="login-page" class="flex h-screen">
      <div id="login-container" class="m-auto rounded">
        <form class="w-full max-w-sm space-y-7">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 class="text-center text-2xl font-bold  text-gray-900">Reset password</h2>
        </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
              Email
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-name"
                type="email"
                placeholder="jane.doe@example.com"
                autocomplete="email" required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div class="md:items-center md:mx-auto md:w-full md:max-w-sm">
              <button
                class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                type="button"
                onClick={onReset}
              >
                Send reset email
              </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
