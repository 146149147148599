import { useEffect, useState } from "react";
import { getAllAutomations, getAutomationById } from "../apis/automation";
import { auth } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import AutomationInfoModal from "./automation/infoModal";
import { useAuthState } from "react-firebase-hooks/auth";

const AutomationList = () => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [automations, setAutomations] = useState([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [loadedPage, setLoadedPage] = useState(false)

  const loadAutomations = async () => {
    const automations = await getAllAutomations();
    setAutomations(automations);
    setLoadedPage(true)
  };

  const goToCreateAutomation = async () => {
    navigate("/automation/selector");
  };

  const openInfo = async (automationIndex) => {
    const automation = automations[automationIndex];
    const automationData = await getAutomationData(automation);
    setSelectedAutomation(automationData);
    setOpenInfoModal(true);
  }

  const goToData = async (id) => {
    navigate(`/automation/data/${id}`)
  }

  useEffect(() => {
    if (user) {
      if (!loadedPage) {
        loadAutomations();
      }
    }
    if (!openInfoModal) {
      setSelectedAutomation(null)
    }
  }, [user, openInfoModal]);

  const getAutomationData = async (automation) => {
    const automationData = await getAutomationById(automation['_id'])
    return automationData
  }

  return (
    <div className="automation-list rounded">
      <AutomationInfoModal openStatus={openInfoModal} handleEvents={() => { setOpenInfoModal(!openInfoModal)}} selectedAutomation={selectedAutomation}/>
      <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex items-center justify-between mb-4">
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
            My Automations
          </h5>
          <button
            className="shadow kupiks-active hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={goToCreateAutomation}
          >
            Create Automation
          </button>
        </div>
        <div className="flow-root">
          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            {automations.map((automation, index) => (
              <li
                key={index}
                className="flex justify-between gap-x-6 py-5 px-4 rounded hover:dark:bg-gray-700 cursor-pointer"
                onClick={() => {goToData(automation._id)}}
              >
                <div className="flex gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="leading-6 text-sm font-medium text-gray-900 truncate dark:text-white">
                      {automation.name}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500 truncate dark:text-gray-400">
                      {automation.emailAddress}@kupiks.co
                    </p>
                    <br/>
                  </div>
                </div>
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  {automation.active ? (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className="flex-none rounded-full bg-emerald-500/30 p-1">
                        <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                      </div>
                      <p className="text-xs leading-5 text-gray-500 dark:text-gray-400">Active</p>
                    </div>
                  ) : (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
                      </div>
                      <p className="text-xs leading-5 text-gray-500 dark:text-gray-400">Inactive</p>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AutomationList;
