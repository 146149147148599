import { auth } from "../../utils/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import DataTable from "../../components/automation/dataTable";
import {
  getAutomationDataById,
  downloadCsv,
  getAutomationById,
} from "../../apis/automation";
import { useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import fileDownload from "js-file-download";
import DeleteAutomation from "../../components/automation/deleteAutomation";
import GoogleSheetsConnector from "../../components/automation/googleSheetsConnect";
import NoDataModal from "../../components/automation/noDataModal";

function App() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState({});
  const [user, loading, error] = useAuthState(auth);
  const [automationInfo, setAutomationInfo] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (user) {
      loadData();
    }
    if (error) {
      console.log("user is logged out");
      navigate("/login");
    }
  }, [id, user, error]);

  const getAutomationData = async () => {
    const automationData = await getAutomationById(id);
    setAutomationInfo(automationData);
  };

  const loadData = async () => {
    try {
      const apiData = await getAutomationDataById(id, 0);
      await getAutomationData();
      setTableData(apiData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadCsv = async () => {
    try {
      const apiData = await downloadCsv(id);
      fileDownload(apiData, `${id}.csv`);
    } catch (error) {
      console.log(error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${automationInfo.emailAddress}@kupiks.co`);
  };

  const goToEmailLogs = () => {
    navigate(`/automation/emails/${id}`);
  };

  return (
    <>
      <div className="min-h-full">
        <Navbar />

        <main className="home-page mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div class="p-2 flex">
            <div class="w-1/2 space-x-2">
              <button
                onClick={() => {
                  handleDownloadCsv();
                }}
                class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold text-xs font-medium py-2 px-4 rounded inline-flex items-center"
              >
                <svg
                  class="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>Download CSV</span>
              </button>
              <button
                onClick={() => {
                  goToEmailLogs();
                }}
                class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold text-xs font-medium py-2 px-4 rounded inline-flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="fill-current w-4 h-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
                <span>Emails log</span>
              </button>
              <DeleteAutomation automationId={id}></DeleteAutomation>
              {automationInfo !== null ? (
                <GoogleSheetsConnector
                  automationId={id}
                  automationInfo={automationInfo}
                ></GoogleSheetsConnector>
              ) : (
                ""
              )}
            </div>
            <div class="w-1/2 flex flex-col items-end">
              {automationInfo !== null ? (
                <NoDataModal openStatus={false} emailAddress={automationInfo.emailAddress}/>
              ) : (
                <span class="text-md font-medium text-gray-900 dark:text-white">
                  Loading automation info
                </span>
              )}
            </div>
          </div>
          <DataTable data={tableData} emailAddress={automationInfo !== null ? automationInfo.emailAddress : ''} />
        </main>
      </div>
    </>
  );
}

export default App;
