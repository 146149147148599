import React from "react";
import "./kupiks.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import googleSheetsLogo from "../imgs/google_sheets.png";
import GoogleSheetsIntegration from "./googleSheetsIntegration";
import { getGoogleStatus, createAutomation } from "../apis/automation";

const integrations = {
  google: {
    provider: "google_sheets",
    name: "Google Sheets",
    logo: googleSheetsLogo,
    modal: GoogleSheetsIntegration,
  },
};

const CreateAutomationForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [openGoogleModal, setOpenGoogleModal] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [output, setOutput] = useState([]);
  const [inputField, setInputField] = useState("");

  const onCreate = async (e) => {
    e.preventDefault();
  };
  const addToInput = (e) => {
    if (inputField === '') {
      return
    }
    for (let index = 0; index < inputs.length; index++) {
      const input = inputs[index];
      if (input.field === inputField) {
        return
      }
    }
    setInputs([
      ...inputs,
      {
        field: inputField,
      },
    ]);
    setInputField("");
  };
  const deleteInput = (e) => {
    console.log(e);
    const result = [...inputs];
    result.splice(e, 1);
    setInputs(result);
  };


  const googleCallback = (data) => {
    setOutput([
      {
        integration: "google_sheets",
        docId: data.docId,
        sheetName: data.sheetName,
      },
    ]);
  };

  const submit = async () => {
    try {
      await createAutomation({
        name: name,
        inputs: inputs,
        outputs: output,
      });
    } catch (error) {
      console.log(error);
    }
    navigate("/home");
  };

  return (
    <>
      <div class="w-full flex justify-center items-center">
        <form class="space-y-4 w-full max-w-lg p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 light:bg-gray-800 light:border-gray-700">
          <h5 class="text-xl font-medium text-gray-900 font-extrabold">Create a new automation</h5>
          <div class="pb-8 mb-4">
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="inline-full-name"
              >
                What will this automation do?
              </label>
            </div>
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-automation-name"
              type="text"
              placeholder="Save business expenses to Google Sheets"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div class="pb-8  mb-4">
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="inline-parse-checkbox"
              >
                What data do you want to parse?
              </label>
            </div>
            <div class="md:flex md:items-center">
              <input
                class="bg-gray-200 md:w-5/6 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-automation-name"
                type="text"
                placeholder="Payment amount, Sender, Date, Product name"
                value={inputField}
                onChange={(e) => setInputField(e.target.value)}
              />
              <button
                class="shadow kupiks-active md:w-1/6 hover:bg-blue-400 focus:shadow-outline text-xs focus:outline-none text-white font-bold  py-3 px-2 rounded"
                type="button"
                onClick={addToInput}
              >
                Add
              </button>
            </div>
            <div class="mb-4 flex space-x-1 space-y-2">
              {inputs.map((input, i) => (
                <span
                  key={i}
                  className="shadow kupiks-spans hover:bg-blue-300 focus:shadow-outline text-xs focus:outline-none text-white font-bold  py-2 px-2 rounded"
                  onClick={() => deleteInput(i)}
                >
                  {input.field}
                </span>
              ))}
            </div>
          </div>
          <div class="mb-4">
            <button
              class="shadow w-full kupiks-active hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={submit}
            >
              Create automation
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateAutomationForm;
