import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


const AutomationInfoModal = ({ openStatus, handleEvents, selectedAutomation }) => {
    const [open, setOpen] = useState(false)
    const [inputs, setInputs] = useState([])
    const [outputs, setOutputs] = useState([])

    useEffect(() => {
        setOpen(openStatus)
        if (selectedAutomation && selectedAutomation.rules?.inputs) {
            setInputs(selectedAutomation.rules.inputs)
        }
        if (selectedAutomation && selectedAutomation.rules?.outputs) {
            setOutputs(['Google Sheets'])
        } else {
            setOutputs(['Kupiks AI'])
        }
    }, [openStatus, open, selectedAutomation])

    const cancelButtonRef = useRef(null)
    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left space-y-4">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                    {(selectedAutomation && selectedAutomation.name) ? selectedAutomation.name : 'Error loading automation'}
                                                </Dialog.Title>
                                                <div className="mt-2 space-y-2">
                                                    <p className="text-md text-gray-600">
                                                        Extracts this data:
                                                    </p>
                                                    <div class="mb-4 flex space-x-1">
                                                        {
                                                            inputs.map((input, i) => (
                                                                <span
                                                                    key={i}
                                                                    className="shadow kupiks-spans hover:bg-blue-300 focus:shadow-outline text-xs focus:outline-none text-white font-bold  py-2 px-2 rounded"
                                                                >
                                                                    {input.field}
                                                                </span>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mt-2 space-y-2">
                                                    <p className="text-md text-gray-600">
                                                        This automations writes to:
                                                    </p>
                                                    <div class="mb-4 flex space-x-1">
                                                        {
                                                            outputs.map((outputs, i) => (
                                                                <span
                                                                    key={i}
                                                                    className="shadow kupiks-spans hover:bg-blue-300 focus:shadow-outline text-xs focus:outline-none text-white font-bold  py-2 px-2 rounded"
                                                                >
                                                                    {outputs}
                                                                </span>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mt-2 space-y-2">
                                                    <p className="text-md text-gray-600">
                                                        Send/Forward your emails to this address:
                                                    </p>
                                                    <div class="mb-4 flex space-x-1">
                                                        <p className="text-sm text-gray-700">
                                                        {(selectedAutomation && selectedAutomation.emailAddress) ? selectedAutomation.emailAddress+'@kupiks.co' : 'Error loading email address'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                            onClick={handleEvents}
                                        >
                                            Show data
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={handleEvents}
                                            ref={cancelButtonRef}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default AutomationInfoModal