import { getUserToken } from "../utils/auth";
import client from './client';

export async function getAllAutomations(){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/automation/getAll', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    return data;
}

export async function getAutomationById(id){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/automation/get', {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            id: id
        }
    })
    return data;
}

export async function downloadCsv(id){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/automation/downloadCsv', {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            id: id
        },
        responseType: 'blob'
    })
    return data;
}

export async function getAutomationDataById(id, page){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/automation/getData', {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            id: id,
            page: page
        }
    })
    return data;
}

export async function getAutomationEmailsById(id, page){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/automation/getEmails', {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            id: id,
            page: page
        }
    })
    return data;
}


export async function createAutomation({name, inputs, outputs}){
    const token = await getUserToken();
    const {data} = await client.post('/api/v1/automation/create', {
        name: name,
        inputs: inputs, 
        outputs: outputs
    } ,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    return data;
}


export async function getGoogleSheetsList(){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/integration/google/sheets', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    return data;
}

export async function getGoogleStatus(){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/integration/google/status', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    return data;
}

export async function getSheetTabs(docId){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/integration/google/sheet', {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            docId: docId
        }
    })
    return data.sheets;
}

export async function deleteAutomationById(automationId){
    const token = await getUserToken();
    const {data} = await client.post('/api/v1/automation/delete',{
        id: automationId
    } ,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    return data;
}