import { useEffect, useState } from "react";

const EmailsTable = ({ data }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data && data.length !== 0) {
      setTableData(data || []);
    }
  }, [data]);

  return (
    <>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Sender
                </th>
                <th scope="col" class="px-6 py-3">
                  Subject
                </th>
                <th scope="col" class="px-6 py-3">
                  Received at
                </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{row.failed? (
                    <div className="mt-1 flex items-center gap-x-1.5">
                        <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
                        </div>
                        <p className="text-sm leading-5 text-gray-500 dark:text-gray-400">Failed to parse</p>
                    </div>
                ): (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className="flex-none rounded-full bg-emerald-500/30 p-1">
                        <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                      </div>
                      <p className="text-sm leading-5 text-gray-500 dark:text-gray-400">Parsed</p>
                    </div>
                )}</td>
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{row.sender? row.sender: 'Unavailable'}</td>
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{row.rawData?.info? row.rawData?.info?.subject : 'Unavailable'}</td>
                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{row.createdAt? new Date(row.createdAt).toLocaleString(): 'Unavailable'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmailsTable;