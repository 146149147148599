import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/home')
    })
    return (
      <div>
            <p>404 Page not found</p>
      </div>
    );
}

export default PageNotFound;