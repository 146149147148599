import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './pages/login/Login';
import Reset from './pages/login/forgotPass';
import Signup from './pages/signup/Signup';
import Home from './pages/home/Home'
import CreateAutomation from './pages/createAutomation/CreateAutomation';
import AutomationData from './pages/automation/automationData';
import AutomationEmails from './pages/automation/automationEmails';
import AutomationSelector from './pages/automation/automationSelector';
import PageNotFound from './pages/PageNotFound';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './utils/firebase';

const openRoutes = [
  '/signup',
  '/reset'
]

function App() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const location = window.location.pathname;
    if (user) {
      const uid = user.uid;
    } 
    if ((error || (!user && !loading)) && !openRoutes.includes(location)) {
      console.log("user is logged out")
      navigate('/login')
    }
  }, [user, loading, error, navigate])

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home" element={<Home />} />
        <Route path="/automation/create" element={<CreateAutomation />} />
        <Route path="/automation/data/:id" element={<AutomationData />}/>
        <Route path="/automation/emails/:id" element={<AutomationEmails />}/>
        <Route path="/automation/selector" element={<AutomationSelector />}/>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
