import { getUserToken } from "../utils/auth";
import client from './client';

export async function signup({email, password}){
    const {data} = await client.post('/api/v1/auth/signup',{
        email: email,
        password: password
    })
    return data;
}

export async function getUserStatus(){
    const token = await getUserToken();
    const {data} = await client.get('/api/v1/auth/status',{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    return data;
}