import { useEffect, useState } from "react";
import NoDataModal from "./noDataModal";

const DataTable = ({ data, emailAddress }) => {
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [emailAddrUi, setEmailAddrUi] = useState('');

  useEffect(() => {
    if (data && data.headers) {
      setTableHeaders(data.headers || []);
    }
    if (data && data.data && data.data.length !== 0) {
      let table = [];
      data.data.forEach((input) => {
        table.push(input.outputData.map((r) => r['result']));
        
      });
      setTableData(table);
    }
    if (emailAddress && emailAddress !== '') {
      setEmailAddrUi(emailAddress);
    }
  }, [data, emailAddress]);

  return (
    <>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {tableHeaders.map((field) => (
                <th scope="col" class="px-6 py-3">
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                {row.map((column) => (
                  <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{column}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {tableData.length === 0? (<NoDataModal openStatus={true} hiddenBtn={true} emailAddress={emailAddrUi}/>): ''}
      </div>
    </>
  );
};

export default DataTable;
