import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";

const NoDataModal = ({ openStatus, emailAddress, hiddenBtn }) => {
  const [open, setOpen] = useState(false);
  const [emailAddrUi, setEmailAddrUi] = useState("");
  const [hideBtnUi, setHideBtnUi] = useState(false);

  useEffect(() => {
    if (emailAddress) {
      setEmailAddrUi(emailAddress);
      setOpen(openStatus);
    }
    if (hiddenBtn) {
      setHideBtnUi(hiddenBtn);
    }
  }, [openStatus, emailAddress, hiddenBtn]);

  const cancelButtonRef = useRef(null);
  return (
    <>
      {!hideBtnUi ? (
        <button
          onClick={() => {
            setOpen(true);
          }}
          type="button"
          class="bg-yellow-300 hover:bg-gray-400 text-gray-800 font-bold text-xs font-medium py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 mr-2 -ml-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
            />
          </svg>
          Start sending data
        </button>
      ) : (
        ""
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed w-full inset-0 z-10 overflow-y-auto">
            <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden overflow-x-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-3/5">
                  <div className="bg-white px-4 pb-4 pt-5 md:p-6 md:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <PaperAirplaneIcon
                          className="h-6 w-6 text-blue-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Send Data
                        </Dialog.Title>
                        <div className="mt-2">
                          <p
                            className="text-sm text-gray-500"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            To start using Kupiks forward your emails to the
                            following address
                            <br/>
                            <a className="text-blue-600 font-medium visited:text-purple-600" target="_blank" href="https://nonchalant-nyala-4aa.notion.site/Sending-emails-for-parsing-5670aba759764d09825018bb9122f286" rel="noreferrer">Learn more</a>
                          </p>
                          <br />
                          <blockquote class="p-4 my-4 border-l-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">
                            <p class="text-sm italic font-medium leading-relaxed text-gray-900 dark:text-white">
                              {emailAddrUi}@kupiks.co
                            </p>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NoDataModal;
