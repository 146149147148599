import { auth } from "../../utils/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from '../../components/navbar';
import EmailsTable from '../../components/automation/emailsTable';
import { getAutomationEmailsById, getAutomationById } from "../../apis/automation";
import { useParams } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';


function App() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([])
  const [user, loading, error] = useAuthState(auth);
  const [automationInfo, setAutomationInfo] = useState(null);

  const { id } = useParams()

  useEffect(() => {
    if (user) {
      loadData()
    } 
    if (error) {
        console.log("user is logged out")
        navigate('/login')
    }
  }, [id, user, error]);

  const getAutomationData = async () => {
    const automationData = await getAutomationById(id)
    setAutomationInfo(automationData)
  }

  const loadData = async () => {
    try {
      const apiData = await getAutomationEmailsById(id, 0)
      setTableData(apiData.result)
    } catch (error) {
      console.log(error)
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${automationInfo.emailAddress}@kupiks.co`);
  }
  const backToData = () => {
    navigate(`/data/${id}`)
  }
  return (
    <>
    <div className="min-h-full">
        <Navbar/>
        
        <main className="home-page mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div class="p-2 flex">
            <div class="w-1/2 space-x-2">
                <button onClick={() => {backToData()}} class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold text-xs font-medium py-2 px-4 rounded inline-flex items-center">
                    <span>Back to data</span>
                </button>
            </div>
            <div class="w-1/2">
            { (automationInfo !== null)? (<span class="text-md font-medium text-gray-900 dark:text-black">Forward/Send your emails to this address - <span onClick={copyToClipboard} title="Copy to clipboard" className="font-semibold text-gray-900 underline decoration-blue-500 cursor-pointer">{automationInfo.emailAddress}@kupiks.co</span></span>) : (<span class="text-md font-medium text-gray-900 dark:text-white">Loading automation info</span>)}
            </div>
          </div>
          <EmailsTable data={tableData}/>
        </main>
    </div>
    </>
  );
}

export default App;
