import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../utils/firebase";
import Navbar from "../../components/navbar";
import invoiceGraphic from "../../imgs/invoice_graphic.svg";
import supportGraphic from "../../imgs/support_graphic.png";
import leadGraphic from "../../imgs/lead_graphic.svg";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { createAutomation } from "../../apis/automation";

function App() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [open, setOpen] = useState(false);

  const { id } = useParams();

  const list = [
    {
      id: "invoice",
      img: invoiceGraphic,
      title: "Invoice parser",
      alt: "Invoice Graphic",
      inputs: [
        {
          field: "Sender",
        },
        {
          field: "Amount due",
        },
        {
          field: "Date due",
        },
        {
          field: "Invoice from",
        },
        {
          field: "Date received",
        },
      ],
    },
    {
      id: "support",
      img: supportGraphic,
      title: "Customer support inquiry parser",
      alt: "Customer support Graphic",
      inputs: [
        {
          field: "Sender email address",
        },
        {
          field: "Sender name",
        },
        {
          field: "Date sent",
        },
        {
          field: "Inquiry details",
        },
        {
          field: "Inquiry topic",
        }
      ],
    },
    {
      id: "lead",
      img: leadGraphic,
      title: "Lead inquiry parser",
      alt: "Customer support Graphic",
      inputs: [
        {
          field: "Sender email address",
        },
        {
          field: "Sender name",
        },
        {
          field: "Sender addtional info",
        },
        {
          field: "Date sent",
        },
        {
          field: "Inquiry details",
        },
      ],
    },
  ];
  const cancelButtonRef = useRef(null);
  useEffect(() => {
    if (user) {
    }
    if (error) {
      console.log("user is logged out");
      navigate("/login");
    }
  }, [user, error]);

  const createPremadeAutomation = async (index) => {
    const result = await createAutomation({
      name: list[index].title,
      inputs: list[index].inputs,
    });
    navigate(`/automation/data/${result.automationId}`);
  };

  const openCustomAutomation = () => {
    navigate('/automation/create')
  }

  return (
    <>
      <div className="min-h-full">
        <Navbar title="Select Automation" />
        <div className="w-full  p-4 rounded-lg shadow sm:p-8">
          <div className="flex items-center justify-between mb-4">
            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
              Automation templates
            </h5>
            <button
              className="shadow kupiks-active hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded inline-flex items-center"
              type="button"
              onClick={openCustomAutomation}
            >
              Create Custom Automation
            </button>
          </div>
          <div>
            <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
              <h2 class="sr-only">Products</h2>

              <div class="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 xl:gap-x-12">
                {list.map((field, index) => (
                  <a
                    key={index}
                    className="group cursor-pointer"
                    onClick={() => createPremadeAutomation(index)}
                  >
                    <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-6 xl:aspect-w-5">
                      <img
                        src={field.img}
                        alt={field.alt}
                        class="h-full w-full object-cover object-center group-hover:opacity-75"
                      />
                    </div>
                    <h3 class="mt-4 text-lg font-bold text-gray-900">
                      {field.title}
                    </h3>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
