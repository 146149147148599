import React from "react";
import "../../components/kupiks.css";
import "./Signup.css";
import { signup } from "../../apis/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const SignupForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fail, setFail] = useState(false);

  const onSignup = async (e) => {
    e.preventDefault();
    try {
      await signup({
        email: email,
        password: password,
      });
      navigate("/login");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setFail(true);
    }
  };
  return (
    <>
      <div id="signup-page" className="signup-page" class="flex h-screen">
        <div id="signup-container" class="m-auto rounded">
          <form class="w-full max-w-sm space-y-4">
            {fail ? (
              <label className="text-red-500 text-sm">
                This email was used before or password is less than 8 characters
              </label>
            ) : null}
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                  for="inline-full-name"
                >
                  Email
                </label>
              </div>
              <div class="md:w-2/3">
                <input
                  class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                  placeholder="jane.doe@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label
                  class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                  for="inline-password"
                >
                  Password
                </label>
              </div>
              <div class="md:w-2/3">
                <input
                  class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-password"
                  type="password"
                  placeholder="******************"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div class="md:flex md:items-center">
              <div class="md:w-1/3"></div>
              <div class="md:w-2/3">
                <button
                  class="shadow kupiks-active hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="button"
                  onClick={onSignup}
                >
                  Sign up
                </button>
              </div>
            </div>
            <div class="text-center">
              <div class="md">
                <label class="block text-gray-500 font-bold  text-xs mb-1 md:mb-0 pr-4">
                  By signing up you agree to our &nbsp;
                  <a class="text-blue-600" href="https://www.kupiks.com/terms-of-service">
                    Terms of service
                  </a>
                  &nbsp;and&nbsp;
                  <a class="text-blue-600" href="https://www.kupiks.com/privacy-policy">
                    Privacy policy
                  </a>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignupForm;
